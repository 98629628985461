//
//
//
//
//
//
//
//


import EventBus from '../EventBus';
import VueContextMenu from 'vue-context-menu'

export default {
  components: {
    VueContextMenu
  },
  props: {
    contextMenuItems: {
      type: Array
    }
  },
  data() {
    return {
      activeNode: null
    }
  },
  methods: {
    open(node) {
      this.activeNode = node
      this.$refs.ctxMenu.open()
    },
    menuItemSelected(item) {
      EventBus.$emit('contextMenuItemSelect', item, this.activeNode)
    }
  },
  created() {
    EventBus.$on('openNodeContextMenu', this.open)
  }
}

