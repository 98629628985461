//
//
//
//
//
//
//
//
//
//
//
//

import EventBus from '../EventBus';

export default {
  data() {
    return {
      nodeDragOver: false,
    }
  },
  methods: {
    draggingEnded() {
      this.draggedNode = null
    },
    dragover(ev) {
      ev.dataTransfer.dropEffect = 'move'
    },
    drop() {
      this.$emit('nodeDrop')
    }
  }
}

